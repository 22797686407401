import React from "react";
import Profile from "./Profile";
import Grid from '@mui/material/Grid';
import { Helmet } from 'react-helmet';

const About = () => {

  return (
    <> 
    <Helmet>
    <title>About Us - Nulfye</title>
    <meta name="description" content="I'm Ankit Shukla, a web designer/developer with 5 years of experience crafting user-friendly, engaging websites, blending design trends and coding innovation." />
    </Helmet>
   
    <div className="static bg-amber-100 h-[90px] md:h-[120px] lg:h-[150px] text-5xl md:text-7xl lg:text-8xl bg-cover bg-center bg-no-repeat">
    <h2 className="mt-0 text-center">About Us</h2>
    </div>
 
    <section className="bg-gray-50 ">
    <Grid item container >
    <Grid item xs={false} sm={false} md={1} lg={1.5} />
    <Grid item xs={12} sm={12} md={10} lg={9} xl={9}>
    <div className="-mx-4 flex flex-wrap items-center ">
    <div className="w-full px-4 lg:w-1/2 mt-2 ">
    <h2 className="titleMainText hover:underline text-2xl md:text-4xl text-left mt-8 lg:mt-20" >Web Designer & Developer</h2>
    <div className="mb-0 text-left text-lg md:text-xl leading-relaxed text-gray-700" data-wow-delay=".15s">My name is Ankit Shukla, and I am a web designer and developer dedicated to creating visually appealing and user-friendly websites.<br/><br/>With five years of experience, I have honed my skills in designing and developing websites that fulfil client needs while captivating and engaging their target audience.<br/><br/>By staying updated on the latest design trends and mastering various coding languages, I bring a unique and innovative approach to every project. Join me in exploring the world of web design and development through my expertise and experiences.  
    <div className="bg-amber-200 text-left p-2 mt-8 w-full lg:mb-32">
    <h3 className="titleMainText hover:underline mt-2 md:mt-8 mb-2 md:mb-4 text-xl md:text-2xl">Skills</h3>
    <ul className="list-none m-0 p-0"> 
      <li className="inline-block p-2 border-2 text-sm md:text-lg">Front End Development</li>
      <li className="inline-block p-2 border-2 text-sm md:text-lg">Affiliate Marketing</li>
			<li className="inline-block p-2 border-2 text-sm md:text-lg">Blogging</li>
			<li className="inline-block p-2 border-2 text-sm md:text-lg">SEO (Search Engine Optimisation)</li>
			<li className="inline-block p-2 border-2 text-sm md:text-lg">JavaScript</li>
			<li className="inline-block p-2 border-2 text-sm md:text-lg">React</li>
			<li className="inline-block p-2 border-2 text-sm md:text-lg">Full stack developer</li>
      <li className="inline-block p-2 border-2 text-sm md:text-lg">Machine learning</li>
			<li className="inline-block p-2 border-2 text-sm md:text-lg">Python</li>
			<li className="inline-block p-2 border-2 text-sm md:text-lg">Next js</li>
			<li className="inline-block p-2 border-2 text-sm md:text-lg">Mongo DB </li>
			<li className="inline-block p-2 border-2 text-sm md:text-lg">SQl Database</li>
			<li className="inline-block p-2 border-2 text-sm md:text-lg">C sharp</li>
			<li className="inline-block p-2 border-2 text-sm md:text-lg">Angular js</li>
			<li className="inline-block p-2 border-2 text-sm md:text-lg">Vue.js</li>
			<li className="inline-block p-2 border-2 text-sm md:text-lg">Prompt Engineering</li>
			<li className="inline-block p-2 border-2 text-sm md:text-lg">Chat GPT</li>
		
    </ul>
    </div>
    </div>
    </div>
    <div className="w-full px-4 lg:w-1/2 mt-10 md:mt-20 lg:mt-20 mb-[440px] md:mb-[440px]">
    <div className="relative mx-auto aspect-[25/24] lg:mr-0 max-w-[400px] ">

    <Profile/>
    </div>
    </div>
    </div>
    
    </Grid>
    < Grid item xs={false} sm={false} md={1} lg={1.5} />
    </Grid>
   

    </section>


     <section >
     <Grid item container >
     <Grid item xs={false} sm={false} md={1} lg={1.5}/>
     <Grid item xs={12} sm={12} md={10} lg={9} xl={9}>
     <div className="border-b border-body-color/[.15] pb-12 dark:border-white/[.15] md:pb-16 lg:pb-20">
     <div className="-mx-4 flex flex-wrap items-center">
     <div className="w-full px-4 lg:w-1/2">
     <div className="wow fadeInUp relative mx-auto mb-24 md:mb-28 mt-16 aspect-[25/24] max-w-[full] max-h-[400px] text-center lg:m-0" data-wow-delay=".15s" >
     <img src='/conpic/cartoonx.webp' alt="" fill className="drop-shadow-three dark:hidden dark:drop-shadow-none h-[500px] w-full" />
     </div>
     </div>

     <div className="w-full px-4 lg:w-1/2">
     <div className="mb-4">
     <h3 className="titleMainText hover:underline mt-6 md:mt-8 lg:mt-12 mb-8 md:mb-10 text-2xl md:text-4xl" align="left">About The Company</h3>
     <p className="text-left text-lg md:text-xl mt-1 md:mt-2 leading-relaxed text-gray-700" align="left">
      Nulfye IT Services was registered in 2023 with an to providing top-notch web designing services in New Delhi and around the globe. so far we have delivered 249+ projects to our clients residing in delhi and all over india.<br/><br/>
      After years of working in different IT companies in Noida (HR), I (Ankit Shukla) decided that it was time to take the leap and turn my dream into a reality. I (Ankit Shukla) believed that I had the knowledge, skills, and drive to succeed in the fast-paced and ever-changing world of technology. so i quit the job and did my first statup.<br/><br/>
      In addition to designing websites, I also create content for them. This may include writing blog posts, product descriptions, or creating videos and images. This helps in making the website more engaging and informative for visitors. <br/><br/>
      With my knowledge and experience in the tech industry, I also offer consulting services to clients. This involves providing guidance and recommendations on their digital presence and strategies to improve their online presence and reach their goals.
     <br/><br/>
      Company was established in the heart of the country , New Delhi with an aim to improve the online presence and branding of businesses. We offer a wide range of services including website design and development, e-commerce solutions, digital marketing, and branding strategies.
      At our company, we believe in forming long-term relationships with our clients by understanding their business goals and providing them with the best possible solutions to achieve those goals. We take a collaborative approach, working closely with our clients to create websites that are not only aesthetically pleasing but also drive business growth.
     </p>
     </div></div></div></div>
    </Grid>
    < Grid item xs={false} sm={false} md={1} lg={1.5}  />
    </Grid>
    </section>

    
    <section className="bg-gray-100 -mb-4">

    <Grid item container >
    <Grid item xs={false} sm={false} md={1} lg={1.5} xl={2}/>
    <Grid item xs={12} sm={12} md={10} lg={9} xl={8}>

    <h3 class='titleMainText hover:underline mt-10 text-left md:mt-12 lg:mt-20 mb-8 md:mb-10 text-2xl md:text-4xl p-2'>Why Choose Us</h3>

    <div className="-mx-4 flex flex-wrap items-center">
    <div className="w-full px-4 lg:w-1/2">
    <div className="mb-10">
    <h3 className="mb-4 text-xl font-bold text-black dark:text-white md:text-2xl hover:underline" align="left">Customized Approach</h3>
    <p className="text-left text-lg md:text-xl mt-1 md:mt-2 leading-relaxed text-gray-700" align="left">we appreciate that your company offers customized web design solutions that are tailored to meet the specific requirements of each client. This means that my website will be unique and stand out from the competition.</p>
    </div>

    <div className="mb-10">
    <h3 className="mb-4 text-xl font-bold text-black dark:text-white md:text-2xl hover:underline" align="left">User-Centered Design </h3>
    <p className="text-left text-lg md:text-xl mt-1 md:mt-2 leading-relaxed text-gray-700" align="left">
    We believe in creating websites that are not only aesthetically pleasing but also user-friendly and easy to navigate. We ensure that the design is intuitive and user-centered, providing a seamless browsing experience for visitors.
    </p></div>
    <div className="mb-10">
    <h3 className="mb-4 text-xl font-bold text-black dark:text-white md:text-2xl hover:underline" align="left">Timely Delivery</h3>
    <p className="text-left text-lg md:text-xl mt-1 md:mt-2 leading-relaxed text-gray-700" align="left">
    We understand the importance of time in the constantly evolving digital world. We strive to deliver projects within the agreed upon timeline, ensuring that our clients can have their website up and running as soon as possible.</p>
    </div>
    <div className="mb-10 md:mb-16 lg:mb-28">
    <h3 className="mb-4 text-xl font-bold text-black dark:text-white md:text-2xl hover:underline" align="left">Responsive and Mobile-Friendly Designs</h3>
    <p className="text-left text-lg md:text-xl mt-1 md:mt-2 leading-relaxed text-gray-700" align="left">
    With the majority of internet users accessing the web through mobile devices, having a responsive and mobile-friendly website is essential. Your company understands this and ensures that all designs are optimized for different screen sizes and devices.</p>
    </div>
    </div>
    <div className="w-full lg:w-1/2">
    <div className="wow fadeInUp mx-auto relative aspect-[25/24] max-w-[full] max-h-[400px] lg:mr-0 px-4 md:px-0" data-wow-delay=".2s" >
    <img  src='/conpic/abt.webp' alt="" fill className="drop-shadow-three max-w-full h-[400px] w-full mb-16 md:mb-24 " />
    </div>
    </div>
    </div>

    </Grid>
    < Grid item xs={false} sm={false} md={1} lg={1.5} xl={2} />
    </Grid>
    </section>

    </>

  );
  }

export default About; 