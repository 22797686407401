import React, {useState} from 'react';
import Navbar from 'react-bootstrap/Navbar';
import BlogItem from './BlogItem';
import { Link } from "react-router-dom";
import { blogList } from './Data';
import Grid from '@mui/material/Grid';
import { Helmet } from 'react-helmet';

const KInfluencers = [44];

const Technology =() =>{
  const [visible, setVisible] = useState(6); 
  
  const handlerLoadMore = () => {
    setVisible((prevValue) => prevValue + 6);
  };


return (
  <> 
<Helmet>
    <title>Technology: Enhancing Innovation with AI-Driven Content Writing Tools - Nulfye</title>
    <meta name="description" content="Explore AI in content writing technology. Discover insights and tips for creating innovative, engaging, and SEO-friendly content that leverages the latest tools." />
    </Helmet>

  <Grid item container >
  <Grid item xs={false} sm={false} md={1} lg={1} xl={1.5}/>
  <Grid item xs={12} sm={12} md={10} lg={10} xl={9}>
  <Navbar className='mb-20 md:mb-24 lg:mb-32 w-full bg-[#075985] h-28 md:h-36 lg:h-44'>
  <h2 className='justify-center text-white text-3xl md:text-5xl mt-10 md:mt-17 lg:mt-20 p-2 ml-1 md:ml-4'><strong>Technology</strong></h2> 
  </Navbar>
         
  <h2 className='mb-8 md:mb-16 lg:mb-20 mr-4 hover:underline md:mr-6 text-lg'>showing 6 out 11 blogs</h2>
  <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 p-1">

  { blogList.filter(blog => KInfluencers.includes(blog.host_id)).reverse().slice(0,visible).map(blog => {
  return <BlogItem blog={blog} key={blog.id}/>
  })}

  </div>
  <div className="mt-8 mb-16 text-2xl md:mt-20 md:mb-20 lg:mb-28 md:text-3xl">
  <Link onClick={handlerLoadMore}>View More</Link>
  </div>
  </Grid>
  < Grid item xs={false} sm={false} md={1} lg={2} xl={2}/>
  </Grid>
  </>
    
  )
  }
  
export default Technology;