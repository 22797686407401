import React, { Component } from "react";

class Tabs extends Component {
  state = {
    selected: this.props.selected || 0
  };

  handleChange(index) {
    this.setState({ selected: index });
  }

  render() {
    
    return (
    <>
    <ul className="bg-slate-800 shadow dark:bg-gray-800 mt-14 mb-20">

    <div className="container lg:text-lg lg:p-6 mx-auto text-gray-600 capitalize dark:text-gray-300 md:text-md md:p-2  ">
    {this.props.children.map((elem, index) => {
    let style = index === this.state.selected ? "selected" : "";
    
    return (
    
    <lil
    key={index}
    className={style}

    onClick={() => this.handleChange(index)}>
    {elem.props.title}
    </lil>
    );
    })}
    </div>

    </ul>
    
    <div className="tab">{this.props.children[this.state.selected]}</div>
    </>
    );
  }
}

export default Tabs;
