import React, {useState,useEffect} from 'react';
import Form from 'react-bootstrap/Form';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import { css } from "@emotion/react";
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { createPost } from '../actions/posts';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { styled} from '@mui/system';
import { createTheme } from '@mui/material/styles';
import './aiar.css';
import { useHistory } from 'react-router-dom';
import { useRef } from 'react';
import Grid from '@mui/material/Grid';
import { deepPurple } from '@mui/material/colors';
import Scroll from '../containers/Scroll';
import { useSelector } from 'react-redux';
import "bootstrap/dist/css/bootstrap.min.css";
import './summary.css';
import write from './write.gif';
import './aiarticles.css';
import { IoMdClose } from "react-icons/io";
import { useParams } from "react-router-dom";
import MicIcon from "./mic.svg";
import { HiPencil } from "react-icons/hi2";

const customTheme = createTheme({
  palette: {
    primary: {
      main: deepPurple[50],
    },
  },
});

const StyledTextField = styled(TextField)`
  ${({ theme }) => `
  cursor: pointer;
  background-color: ${theme.palette.primary.main};
  transition: ${theme.transitions.create(['background-color', 'transform'], {
    duration: theme.transitions.duration.standard,
  })};
  &:hover {
    background-color: ${theme.palette.secondary.main};
    transform: scale(1.1);
  }
  `}
`;


const BackdropUnstyled = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ 'MuiBackdrop-open': open }, className)}
      ref={ref}
      {...other}
    />
  );
});

BackdropUnstyled.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

const Backdrop = styled(BackdropUnstyled)`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = (theme) => ({
  width: 400,
  bgcolor: theme.palette.mode === 'dark' ? '#0A1929' : 'white',
  border: '2px solid currentColor',
  padding: '16px 32px 24px 32px',
});

const icon = (
  <Paper sx={{ m: 1  }} elevation={4}>
    <Box component="svg" sx={{ width: 100, height: 100 }}>
      <Box
        sx={{
          fill: (theme) => theme.palette.common.white,
          stroke: (theme) => theme.palette.divider,
          strokeWidth: 1,
        }}
        points="0,100 50,00, 100,100"
      />
    </Box>
  </Paper>
);
  
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const { Configuration, OpenAIApi } = require("openai");

const AiArticles = ()=> {
  const { post, posts, isLoading } = useSelector((state) => state.posts);
  const [progress, setProgress] = React.useState(0);
  const [percent, setPercent] = useState(0);
  const btnRef = useRef(null);
  const btnnRef = useRef(null);
  const ref = useRef(null);
  const [results, setResults] = useState([]);
  const history = useHistory();
  const [ postData , setPostData] = useState({heading:'', response :[]});
  const [keywords , setKeywords] = useState({ title: '' , body: '' });
  const [article, setArticle] = useState([]);
  const [timer, setTimer] = useState(0);
  const [skills, setSkills] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showing, setShowing] = useState(false);
 
 
  //const [data] = useState(myDataSet); // Assuming myDataSet is available in your scope
  const [preview, setPreview] = useState(false);
  const textRef = useRef(null);

  const user = JSON.parse(localStorage.getItem('profile'));
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { query } = useParams();
  const [searchQuery, setSearchQuery] = useState(query || "");

  const handleIconButtonClick = pageUrl => {
  history.push(pageUrl)
  }    

  const handleClick = () => {
  ref.current?.scrollIntoView({ behavior: 'smooth' });
  };


  const searchQueryHandler = (event) => {
    if (event?.key === "Enter" && searchQuery?.length > 0) {
        history(`/${searchQuery}/${1}`);
    }
   };
      
  const onFormSubmit =  e => {
  e.preventDefault();
  dispatch(createPost({ ...postData}));
  const formData = new FormData(e.target),
  formDataObj = Object.fromEntries(formData.entries());
  e.target.reset();

  const configuration  = new Configuration({
  apiKey: process.env.OPENAI_API_KEY,               
  });
              
  const openai = new OpenAIApi(configuration);

  openai.createCompletion({
  model: "gpt-3.5-turbo-instruct",
  prompt: `Generate top 4 keywords title related to the topic in to a professional  manner ,using the title provided.\nTitle: {}\n*.${formDataObj.articleTopic}`,
  temperature:  0.7, 
  max_tokens: 150,
  top_p: 1,
  frequency_penalty: 0,
  presence_penalty: 0,

  })

  .then ((response ) => {
  setPostData({
  heading : `${formDataObj.articleTopic}`,
  response: response.data.choices[0].text.split('\n').slice(2),
  });
  });
  };


  const onFormSend =  e => {
  e.preventDefault();
  dispatch(createPost({ ...keywords,article }));


  const formData = new FormData(e.target),
  formDataObj = Object.fromEntries(formData.entries());
  e.target.reset();

  const configuration  = new Configuration({
  apiKey: process.env.OPENAI_API_KEY,               
  });
              
  const openai = new OpenAIApi(configuration); 
  openai.createCompletion({
  model: "gpt-3.5-turbo-instruct",
  prompt: `generate defination on a given topic.\nTitle: {}\n.${formDataObj.articleSectionHeading}`,
  temperature:  0.7, 
  max_tokens:  350,
  top_p: 1,
  frequency_penalty: 0,
  presence_penalty: 0,

  })

  .then ((response ) => {
  setKeywords({
  title : `${formDataObj.articleSectionHeading}`,
  body :`${response.data.choices[0].text}`,

  });
  });

  let movies = [];
  for(let i=0; i<skills.length; i++){
  movies.push(

  openai.createCompletion({
  model: "gpt-3.5-turbo-instruct",
  prompt: `Generate detailed article section write up for the following article section heading, using the title provided.\nTitle: {}\nArticle Section Heading:{}\n.${skills[i]}`,
  temperature:  0.9, 
  max_tokens: 350,
  top_p: 0.9,
  frequency_penalty: 1,
  presence_penalty: 0,
  
  })
  .then ((response ) => {
  if(response.data && response.data.choices && response.data.choices.length > 0){
  Promise.all(movies)
  setArticle(article=>[article,response.data.choices[0].text])
  }})
  )} 
  }
     
  function checkboxHandler(e){
  let isSelected = e.target.checked;
  let value = parseInt(e.target.value);
  
  if( isSelected ){
  setSkills([...skills, value])
  }else{
  setSkills((prevData)=>{
  return prevData.filter((id)=>{
  return id!==value
  })
  })
  }
  }

  function checkAllHandler(){
	if( postData.response.length === skills.length){
  setSkills([])
  }else{
  const postIds = postData.response.map((item)=>{
  return item
  })
  setSkills(postIds)
  }
  }

  useEffect(() => {
  if (timer) {
  clearTimeout(timer);
  }
  setTimer(
  setTimeout(() => {
  btnRef.current.click();
  }, 17000)); 
  }, [])


  useEffect(() => {
  if (timer) {
  clearTimeout(timer); 
  }
  setTimer(
  setTimeout(() => {
  btnnRef.current.click();
  }, 20000)); 
  },[]); 

  
  useEffect(() => {
  if (loading) {
  setTimeout(() => {
  setLoading(false);
  }, 2000);
  }
  }, [loading]);
  
  
  const helloHandeler = () => {
  setLoading(!loading);
  setTimeout(() => {
  setLoading(!loading);
  setShowing(!showing);
  }, 2000);
  };

  React.useEffect(() => {
  const timer = setInterval(() => {
  setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
  }, 1800);
  return () => {
  clearInterval(timer);
  };
  }, []);





  const handlePreviewClick = (e) => {
    e.preventDefault();
    setPreview(true);
  };

  const handleEditClick = (e) => {
    e.preventDefault();
    setPreview(false);
  };

  const renderPreview = () => {
    if (textRef.current) {
      const htmlText = { __html: textRef.current.value };
      return (
        <div>
          <div className="preview-box">
            <span className="preview-title">Preview</span>
            <div dangerouslySetInnerHTML={htmlText}></div>
          </div>
          <button onClick={handleEditClick}>Edit</button>
        </div>
      );
    }
    return null;
  };

  const editStyle = { display: preview ? 'none' : 'block' };
  const previewStyle = { display: preview ? 'block' : 'none' };

 
  

  return ( 
    <>
    <div className='min-h-[80vh]'>
    <div className="head_text" ><br/>
    <span className='spa'>Our's </span><span className='spb '>Newly</span> <span className='spc '>Build</span> <span className='spd '>Intelligence</span> <span className='spe '>For</span><br className='max-md:hidden'/>

   {/* <span >Intelligence</span> <span >That</span> <span >Writes</span> <span >Articles</span><br className='max-md:hidden'/>*/}
     &nbsp;
    <span className='blue_gradient'>Effortlessly</span>
    </div>
    <div  class="heado_text">
    <span >N</span>&nbsp;<span>u</span>&nbsp;<span>l</span>&nbsp;<span >F</span> <span >y</span>  <span className='spc'>e</span> <span className='blue_gradient '>.com</span>
    </div>
     
    <Form onSubmit={onFormSubmit} >
    <Form.Group  className="mb-3" controlId='formBasicEmail'>
    <Form.Label>  <Typography>
    </Typography> </Form.Label>
    <br/>

    {/* <ThemeProvider theme={customTheme}>

    <TextField   type ="text" name="articleTopic" variant="outlined" placeholder= "Enter Your Title Here" align="center" 
     required='true'  value={postData.heading} onChange={(e) => setPostData({ ...postData, heading: e.target.value })}   sx={{'& > :not(style)': { m: 1, maxWidth: '65ch' ,width: 'flex' ,borderRadius: 8 ,textAlign:"right"},display:{xs:'flex'} }}/> 

    </ThemeProvider>*/}

   {/* <link rel="stylesheet" href="https://unpkg.com/flowbite@1.4.4/dist/flowbite.min.css" />

    <div class="max-w-2xl mx-auto ">

	<form >   
        <div class="relative flex flex-wrap">
            <div class=" inset-y-0 z-10 left-0 items-top pl-3  flex absolute  my-[15px] pointer-events-none mr-8 ">
            <svg class="w-5 h-6 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>

             </div>

            <input type="text" name="search" value={postData.heading} id="search" class="block placeholder:ml-8 w-full text-md text-gray-900 bg-gray-50 placeholder:mr-80 rounded-xl border border-gray-300 focus:ring-gray-200 focus:border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 "         placeholder="Enter your text here"  required  onChange={(e) => setPostData({ ...postData, heading: e.target.value })}/>

        </div>
        

    </form>

   </div>*/}

{/*<div class="w-full max-w-sm min-w-[300px] relative ">

 
  <div class="relative">
    <input type="email" class="w-full bg-transparent placeholder:text-slate-400 text-slate-700  text-sm border border-slate-200 rounded-2xl pr-3 pl-20 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow" placeholder="Enter your text" />
    
  </div>
</div>*/}


   <div  id="searchBox" className="h-[56px] w-full md:w-[584px] ml-0  md:ml-[] lg:ml-[550px] flex items-center gap-3 px-4 border border-[#dfe1e5] focus:ring-gray-200 focus:border-gray-200 rounded-3xl hover:bg-white hover:shadow-c hover:border-0 focus-within:shadow-c focus-within:border-0">
            <HiPencil  color="#9aa0a6" className='h-[200px]'/>
            <input
                type="text"
                name="articleTopic"
                onChange={(e) => setPostData({ ...postData, heading: e.target.value })}
                value={postData.heading}
                className="grow outline-8 text-black/[0.87]"

                placeholder= "Enter Your Title Here"
                onKeyUp={searchQueryHandler}
            autofocus
              
            />
            <div className="flex items-center gap-3">
                {searchQuery && (
                    <IoMdClose
                        size={24}
                        color="#70757a"
                        className="cursor-pointer"
                        onClick={() => setSearchQuery("")}
                    />
                )}
          { /* <img className="h-6 w-6 cursor-pointer" src={MicIcon} alt=""/>*/}
                
            </div>
        </div>

    </Form.Group>

    <Button   variant='contained' size="medium"  type="submit" color="inherit"  disabled={!postData.heading}  onClick={()=>{handleOpen();setShow(!show);helloHandeler();}} onKeyDown={e => e.key === 'Enter' ? onFormSubmit: ''} >
    <Typography  color="black" >Create Article</Typography>
    </Button>  
 
    </Form>

    <Form   className ="beauty" onSubmit={onFormSend} align ="center">
    <Form.Group  className="mb-3" controlId='formBasicEmail'>
    <Form.Label>  <Typography>
    </Typography> </Form.Label>

    <Grid item container >
    <Grid item xs={false} sm={false} md={2}/>
    <Grid item xs={12} sm={12} md={8} >

    <Paper  variant="outlined" sx={{display:{xs:'none'}}}>
    <TextField name="articleSectionHeading" variant="standard" value={postData.heading} />

    { 
    postData?.response?.map((c,i) =>{
    return(
    <tr key={i} >
    <td>{c}</td>
    <td>
    <input
    type="checkbox"
    name="articleSubTopic"
    value={c}
    checked={skills} 
    onChange={checkboxHandler}
    />
    </td> 
    </tr>) 
    })}
    </Paper>

    </Grid>
    <Grid item xs={false} sm={false} md={2} />
    </Grid>
    </Form.Group>
    <Button type="submit" ref={btnRef} onClick={checkAllHandler} sx={{display:{xs:'none'}}} disabled={!postData.heading}> <Typography>Create Article</Typography> </Button>
    <Button variant='contained' size="medium"  type="submit"  color="inherit" ref={btnnRef} disabled={!postData.heading}  onClick = {handleClick} sx={{display:{xs:'none'}}}   >
   
    <Typography>Create Article</Typography> </Button>
    </Form>


  {/* Show Article */}
  <Grid item container >
  <Grid item xs={false} sm={false} md={1} lg={1} xl={2}/>
  <Grid item xs={12} sm={12} md={10} lg={10} xl={8}>
 
  { loading ? 
   
    <div class="overlap">
     <div class="overlap__inner">
     <div class="overlap__content">  
     <img src={write} height={1110} alt="Loading ..."/>
      Writing...
     </div>
     </div>
     </div>

  : show&& 


 <div>
  <form>
    <div style={editStyle}>
    <textarea ref={textRef} className="textarea" defaultValue={postData?.heading.toLocaleUpperCase()}></textarea>
      <button onClick={handlePreviewClick}>Preview</button>
    </div>
    <div style={previewStyle}>
      {renderPreview()}
    </div>
  </form>
</div>

 /* <div className='summary_box' style={{height:"auto"}}>

  <h1 class='ciby' align='center'>{postData?.heading.toLocaleUpperCase()}</h1>
  

  <Divider color="black" sx={{mt:3}}/>
  <Typography  sx={{ mt:5}} align='left'>{keywords.body}</Typography>
  <ImageAd/>

  { article.map((d,i) => {
  return(
  <div key={i} ><br/>
  <Typography align='left'>{d}</Typography>
  </div>
  )})}
  </div>*/}
  
  </Grid>
  <Grid item xs={false} sm={false} md={1} lg={1} xl={2}/>
  </Grid>
  <div style={{ height: '2rem' }} />
  <div ref={ref}></div>
  <Scroll/>
  </div>
  </>
  );
  } 

export default AiArticles;