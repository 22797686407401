import React, { useState } from 'react';
import './emoji.css';

const Action = ({description="Here is an intresting blog link from nulfye.com"}) => {
  const [likeStatus, setLikeStatus] = useState("inactive");
  const [dislikeStatus, setDislikeStatus] = useState("inactive");
  const [likeCount, setLikeCount] = useState(0);

  const url = window.location.href

  function ShareWebAPI() {
    if (navigator.share) {
      navigator
        .share({
          title: description,
          url: url
        })
        .catch(err => alert("Error Sharing: " + err))
    }
  }

  const liked = () => {
    if (likeStatus === "inactive") {
      setLikeStatus("active");
      setDislikeStatus("inactive");
      setLikeCount(likeCount + 1);
    } else {
      setLikeStatus("inactive");
      setLikeCount(likeCount - 1);
    }
  };

  const disliked = () => {
    if (dislikeStatus === "inactive") {
      setDislikeStatus("active");
      setLikeStatus("inactive");
      setLikeCount(likeStatus === "active" ? likeCount - 1 : likeCount);
    } else {
      setDislikeStatus("inactive");
    }
  };

 
return (
  
  <div className='flex flex-wrap'>
  <div className="flex relative w-[160px] h-[55px] bg-[#fcfcfc] rounded-full overflow-hidden mb-8">
  <div className={`like ${likeStatus}`} onClick={liked} data-status={likeStatus}>  


  <i className={`fa-${likeStatus === "active" ? "solid" : "regular"} 

  ${ likeStatus === "active" ? "like__animation" : "" }`}>

  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="black" class="size-6 md:size-8">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z" />
  </svg></i>
  
  {/*<span className="number">{likeCount}</span>*/}
  </div>

  <span className="divider"></span>

  <div className={`dislike ${dislikeStatus}`} onClick={disliked} data-status={dislikeStatus}>
  
  <i className={`fa-${dislikeStatus === "active" ? "solid" : "regular"}  ${ dislikeStatus === "active" ? "dislike__animation" : "" }`} >
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="black" class="size-6 md:size-8">
  <path stroke-linecap="round" stroke-linejoin="round" d="M7.498 15.25H4.372c-1.026 0-1.945-.694-2.054-1.715a12.137 12.137 0 0 1-.068-1.285c0-2.848.992-5.464 2.649-7.521C5.287 4.247 5.886 4 6.504 4h4.016a4.5 4.5 0 0 1 1.423.23l3.114 1.04a4.5 4.5 0 0 0 1.423.23h1.294M7.498 15.25c.618 0 .991.724.725 1.282A7.471 7.471 0 0 0 7.5 19.75 2.25 2.25 0 0 0 9.75 22a.75.75 0 0 0 .75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 0 0 2.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384m-10.253 1.5H9.7m8.075-9.75c.01.05.027.1.05.148.593 1.2.925 2.55.925 3.977 0 1.487-.36 2.89-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398-.306.774-1.086 1.227-1.918 1.227h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 0 0 .303-.54" />
  </svg>
  </i>
  </div>
  </div>

  <div>
	<ul class="flex flex-wrap gap-2 md:gap-4 ml-2 md:ml-1 md:ml-6 mb-8">

  <li><h1 className='mt-3'><b>Share:</b></h1></li>

	<li><a href={`https://www.facebook.com/sharer/sharer.php?u=${url}`} target="_blank" title="facebook"> <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 48 48">
  <linearGradient id="Ld6sqrtcxMyckEl6xeDdMa_uLWV5A9vXIPu_gr1" x1="9.993" x2="40.615" y1="9.993" y2="40.615" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#2aa4f4"></stop><stop offset="1" stop-color="#007ad9"></stop></linearGradient><path fill="url(#Ld6sqrtcxMyckEl6xeDdMa_uLWV5A9vXIPu_gr1)" d="M24,4C12.954,4,4,12.954,4,24s8.954,20,20,20s20-8.954,20-20S35.046,4,24,4z"></path><path fill="#fff" d="M26.707,29.301h5.176l0.813-5.258h-5.989v-2.874c0-2.184,0.714-4.121,2.757-4.121h3.283V12.46 c-0.577-0.078-1.797-0.248-4.102-0.248c-4.814,0-7.636,2.542-7.636,8.334v3.498H16.06v5.258h4.948v14.452 C21.988,43.9,22.981,44,24,44c0.921,0,1.82-0.084,2.707-0.204V29.301z"></path>
  </svg> 
  </a></li>

	<li><a href={`https://twitter.com/intent/tweet?url=${url}&text=${encodeURI(description)}`} target="_blank" title="twitter"> <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 48 48">
  <linearGradient id="_osn9zIN2f6RhTsY8WhY4a_5MQ0gPAYYx7a_gr1" x1="10.341" x2="40.798" y1="8.312" y2="38.769" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#2aa4f4"></stop><stop offset="1" stop-color="#007ad9"></stop></linearGradient><path fill="url(#_osn9zIN2f6RhTsY8WhY4a_5MQ0gPAYYx7a_gr1)" d="M46.105,11.02c-1.551,0.687-3.219,1.145-4.979,1.362c1.789-1.062,3.166-2.756,3.812-4.758	c-1.674,0.981-3.529,1.702-5.502,2.082C37.86,8.036,35.612,7,33.122,7c-4.783,0-8.661,3.843-8.661,8.582	c0,0.671,0.079,1.324,0.226,1.958c-7.196-0.361-13.579-3.782-17.849-8.974c-0.75,1.269-1.172,2.754-1.172,4.322	c0,2.979,1.525,5.602,3.851,7.147c-1.42-0.043-2.756-0.438-3.926-1.072c0,0.026,0,0.064,0,0.101c0,4.163,2.986,7.63,6.944,8.419	c-0.723,0.198-1.488,0.308-2.276,0.308c-0.559,0-1.104-0.063-1.632-0.158c1.102,3.402,4.299,5.889,8.087,5.963	c-2.964,2.298-6.697,3.674-10.756,3.674c-0.701,0-1.387-0.04-2.065-0.122C7.73,39.577,12.283,41,17.171,41	c15.927,0,24.641-13.079,24.641-24.426c0-0.372-0.012-0.742-0.029-1.108C43.483,14.265,44.948,12.751,46.105,11.02"></path>
  </svg>
  </a></li>


	<li><a href={`https://www.reddit.com/submit?url=${url}&text=${encodeURI(description)}`} target="_blank" title="reddit">   <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 100 100">
  <g opacity=".35"><circle cx="52" cy="52" r="44"></circle><path d="M52,91c-21.505,0-39-17.495-39-39s17.495-39,39-39s39,17.495,39,39S73.505,91,52,91z"></path></g><circle cx="50" cy="50" r="44" fill="#f2f2f2"></circle><path fill="#40396e" d="M50,89c-21.505,0-39-17.495-39-39s17.495-39,39-39s39,17.495,39,39S71.505,89,50,89z"></path><circle cx="50" cy="50" r="37.5" fill="#ef8630"></circle><path fill="#40396e" d="M50,89c-21.505,0-39-17.495-39-39s17.495-39,39-39s39,17.495,39,39S71.505,89,50,89z M50,14 c-19.851,0-36,16.149-36,36s16.149,36,36,36s36-16.149,36-36S69.851,14,50,14z"></path><g><path fill="#f2f2f2" d="M76.016,50c0-3.165-2.56-5.678-5.678-5.678c-1.536,0-2.932,0.605-3.956,1.582 c-3.909-2.792-9.261-4.607-15.218-4.84l2.606-12.193l8.47,1.815c0.093,2.141,1.862,3.863,4.049,3.863 c2.234,0,4.049-1.815,4.049-4.049c0-2.234-1.815-4.049-4.049-4.049c-1.582,0-2.979,0.931-3.63,2.28l-9.447-2.001 c-0.279-0.047-0.558,0-0.745,0.14c-0.233,0.14-0.372,0.372-0.419,0.652l-2.885,13.59c-6.05,0.186-11.495,2.001-15.451,4.84 c-1.024-0.977-2.42-1.582-3.956-1.582c-3.165,0-5.678,2.56-5.678,5.678c0,2.327,1.396,4.282,3.351,5.166 c-0.093,0.558-0.14,1.117-0.14,1.722c0,8.749,10.192,15.87,22.758,15.87s22.758-7.074,22.758-15.87c0-0.558-0.047-1.163-0.14-1.722 C74.619,54.328,76.016,52.327,76.016,50z M37.016,54.049c0-2.234,1.815-4.049,4.049-4.049c2.234,0,4.049,1.815,4.049,4.049 c0,2.234-1.815,4.049-4.049,4.049C38.831,58.144,37.016,56.283,37.016,54.049z M59.68,64.8c-2.792,2.792-8.098,2.979-9.634,2.979 c-1.582,0-6.888-0.233-9.634-2.979c-0.419-0.419-0.419-1.07,0-1.489c0.419-0.419,1.07-0.419,1.489,0 c1.769,1.769,5.492,2.374,8.144,2.374c2.653,0,6.422-0.605,8.144-2.374c0.419-0.419,1.07-0.419,1.489,0 C60.053,63.729,60.053,64.381,59.68,64.8z M58.936,58.144c-2.234,0-4.049-1.815-4.049-4.049s1.815-4.049,4.049-4.049 s4.049,1.815,4.049,4.049C62.984,56.283,61.169,58.144,58.936,58.144z"></path></g>
  </svg>
  </a></li>

	<li><a href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}`} target="_blank" title="linkedin">    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 100 100">
  <path d="M23.5,93C16.607,93,11,87.393,11,80.5v-57C11,16.607,16.607,11,23.5,11h57C87.393,11,93,16.607,93,23.5v57 C93,87.393,87.393,93,80.5,93H23.5z" opacity=".35"></path><path fill="#f2f2f2" d="M21.5,91C14.607,91,9,85.393,9,78.5v-57C9,14.607,14.607,9,21.5,9h57C85.393,9,91,14.607,91,21.5v57 C91,85.393,85.393,91,78.5,91H21.5z"></path><path fill="#70bfff" d="M15.5,78.5v-57c0-3.314,2.686-6,6-6h57c3.314,0,6,2.686,6,6v57c0,3.314-2.686,6-6,6h-57 C18.186,84.5,15.5,81.814,15.5,78.5z"></path><path fill="#40396e" d="M78.5,86h-57c-4.136,0-7.5-3.364-7.5-7.5v-57c0-4.136,3.364-7.5,7.5-7.5h57 c4.136,0,7.5,3.364,7.5,7.5v57C86,82.636,82.636,86,78.5,86z M21.5,17c-2.481,0-4.5,2.019-4.5,4.5v57c0,2.481,2.019,4.5,4.5,4.5h57 c2.481,0,4.5-2.019,4.5-4.5v-57c0-2.481-2.019-4.5-4.5-4.5H21.5z"></path><rect width="9" height="30" x="29" y="41" fill="#f2f2f2"></rect><path fill="#f2f2f2" d="M33.484,38h-0.049c-2.59,0-4.265-1.93-4.265-4.341c0-2.463,1.727-4.338,4.364-4.338 c2.64,0,4.267,1.875,4.315,4.338C37.849,36.068,36.174,38,33.484,38z"></path><g><path fill="#f2f2f2" d="M70.99,71h-8.729V54.752c0-3.837-2.139-6.456-5.573-6.456c-2.62,0-4.038,1.767-4.726,3.474 c-0.251,0.611-0.176,2.301-0.176,3.155V71h-8.729V40.958h8.729v4.567c1.259-1.948,3.23-4.567,8.272-4.567 c6.247,0,10.931,3.928,10.931,12.699L70.99,71L70.99,71z"></path></g>
  </svg>
  </a></li>

	</ul>
	</div>
  
  </div>

  );
  };

export default Action;
