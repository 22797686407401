import React from 'react';
import { Card, CardHeader, CardBody, CardFooter, Typography } from "@material-tailwind/react";
import { Button } from "@material-tailwind/react";
import './profile.css';

const Profile = () => {
    
return (
  <>
  <Card className='lg:w-full max-h-[750px]'>
  <CardHeader shadow={false} floated={false} >
    <img
      src="/images/shukla.webp"
      alt="card"
      className=" w-full h-[550px] object-cover"
    />
  </CardHeader>
 

  <CardBody className="text-center">
        <Typography color="blue-gray-500" className="mb-2 mt-2 text-lg md:text-xl lg:text-2xl ">
          <strong>Ankit Shukla</strong>
        </Typography>
        <Typography color="blue-gray" className="font-large text-lg md:text-xl lg:text-2xl">
         ( The Founder ) 
        </Typography>
  </CardBody>

  <CardFooter className="pt-0 mt-4 mb-4 mr-8 ml-8 ">
   <a href="/contact/">
        <Button
          ripple={false}
          fullWidth={true}
          className="bg-orange-600 mb-8 text-blue-gray-900 shadow-none hover:scale-105 hover:shadow-none focus:scale-105 focus:shadow-none active:scale-100"
        >
        <Typography color="white" className="font-large">
          Contact Us
          </Typography>
        </Button></a>

      </CardFooter>
      <footer >
      <div>
      <p><ion-icon size="medium" name="heart" ></ion-icon></p>
      <p >1.&nbsp;5&nbsp;K 
      </p>
      </div>
      <div >
      <p >Projects</p>
      <p>250</p>
      </div>
      </footer>
     </Card>
  
  
   </>
  )
  }

export default Profile;