import React from 'react';
import Grid from '@mui/material/Grid';
import { Helmet } from 'react-helmet';

const Privacy = () => {
  
return(
  <> 
<Helmet>
    <title>Nulfye Privacy Policy </title>
    <meta name="description" content="Read our Privacy Policy to learn how we protect your data while you explore our AI-driven content writing blogs. Your privacy is our top priority." />
    </Helmet>
  <Grid item container >
  <Grid item xs={false} sm={false} md={1} lg={2} xl={2} />
  <Grid item xs={12} sm={12} md={10}  lg={8} xl={8}>
  
  <h2 className='text-gray-800 text-left text-3xl md:text-5xl p-2 mt-10 md:mt-20 lg:mt-24'><strong>Privacy Policy of Nulfye</strong></h2>

  <p className='text-left p-2 text-lg md:text-xl mt-1 md:mt-2'>Nulfye operates the https://nulfye.com website, which provides the SERVICE.<br/><br/>
  This page is used to inform website visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service, the Nulfye.com website.<br/><br/>
  If you choose to use our Service, then you agree to the collection and use of information in relation with this policy. The Personal Information that we collect are used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.<br/><br/>
  The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at https://nulfye.com, unless otherwise defined in this Privacy Policy.</p>

  <h3 className='text-gray-800 text-left text-2xl lg:text-3xl p-2 mt-4 md:mt-7 lg:mt-10'><strong>Information Collection and Use</strong></h3>
  <p className='text-left p-2 text-lg md:text-xl mt-1 md:mt-2'>For a better experience while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to your name, phone number, and postal address. The information that we collect will be used to contact or identify you.</p>

  <h3 className='text-gray-800 text-left text-2xl lg:text-3xl p-2 mt-4 md:mt-7 lg:mt-10'><strong>Log Data</strong></h3>
  <p className='text-left p-2 text-lg md:text-xl mt-1 md:mt-2'>We want to inform you that whenever you visit our Service, we collect information that your browser sends to us that is called Log Data. This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser version, pages of our Service that you visit, the time and date of your visit, the time spent on those pages, and other statistics.</p>

  <h3 className='text-gray-800 text-left text-2xl lg:text-3xl p-2 mt-4 md:mt-7 lg:mt-10'><strong>Cookies</strong></h3>
  <p className='text-left p-2 text-lg md:text-xl mt-1 md:mt-2'>Cookies are files with small amount of data that is commonly used an anonymous unique identifier. These are sent to your browser from the website that you visit and are stored on your computer's hard drive.<br/><br/>
  Our website uses these "cookies" to collection information and to improve our Service. You have the option to either accept or refuse these cookies, and know when a cookie is being sent to your computer. If you choose to refuse our cookies, you may not be able to use some portions of our Service.</p>

  <h3 className='text-gray-800 text-left text-2xl lg:text-3xl p-2 mt-4 md:mt-7 lg:mt-10'><strong>Service Providers</strong></h3>
  <p className='text-left p-2 text-lg md:text-xl mt-1 md:mt-2'>We may employ third-party companies and individuals due to the following reasons:</p>
  
  <ul class='list-disc p-4 md:p-6 lg:p-6 ml-2 md:ml-6 lg:ml-8'>
  <li className='text-left text-md md:text-lg mt-1'>To facilitate our Service;</li>
  <li className='text-left text-md md:text-lg mt-1'>To provide the Service on our behalf;</li>
  <li className='text-left text-md md:text-lg mt-1'>To perform Service-related services; or</li>
  <li className='text-left text-md md:text-lg mt-1'>To assist us in analyzing how our Service is used.</li>
  </ul>

  <p className='text-left p-2 text-lg md:text-xl mt-1 md:mt-2'>We want to inform our Service users that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>

  <h3 className='text-gray-800 text-left text-2xl lg:text-3xl p-2 mt-4 md:mt-7 lg:mt-10'><strong>Security</strong></h3>
  <p className='text-left p-2 text-lg md:text-xl mt-1 md:mt-2'>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>

  <h3 className='text-gray-800 text-left text-2xl lg:text-3xl p-2 mt-4 md:mt-7 lg:mt-10'><strong>Links to Other Sites</strong></h3>
  <p className='text-left p-2 text-lg md:text-xl mt-1 md:mt-2'>Our Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over, and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>

  <h3 className='text-gray-800 text-left text-2xl lg:text-3xl p-2 mt-4 md:mt-7 lg:mt-10'><strong>Children's Privacy</strong></h3>
  <p className='text-left p-2 text-lg md:text-xl mt-1 md:mt-2'>Our Services do not address anyone under the age of 13. We do not knowingly collect personal identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.</p>

  <h3 className='text-gray-800 text-left text-2xl lg:text-3xl p-2 mt-4 md:mt-7 lg:mt-10'><strong>Changes to This Privacy Policy</strong></h3>
  <p className='text-left p-2 text-lg md:text-xl mt-1 md:mt-2'>We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, after they are posted on this page. Our Privacy Policy was created with the help of the <a href="https://www.privacypolicytemplate.net">Privacy Policy Template.</a></p>

  <h3 className='text-gray-800 text-left text-2xl lg:text-3xl p-2 mt-4 md:mt-7 lg:mt-10'><strong>Contact Us</strong></h3>
  <p className='text-left p-2 text-lg md:text-xl mt-1 md:mt-2 mb-16 md:mb-24 lg:mb-32'>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us from ous contact us page.</p>

  <Grid item xs={false} sm={false} md={1} lg={1} xl={2} />
  </Grid>
  </Grid>
      
  </>

  )
  }

export default Privacy;