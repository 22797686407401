import React from 'react';

const Loop =() =>{

return(

    <div class="text-center mt-96">

    <div class="spinner-grow text-danger" role="status">
    <span class="visually-hidden">Loading...</span>
    </div>

    <div class="spinner-grow text-success" role="status">
    <span class="visually-hidden">Loading...</span>
    </div>

    <div class="spinner-grow text-warning " role="status">
    <span class="visually-hidden">Loading...</span>
    </div>

    <div class="spinner-grow text-primary" role="status">
    <span class="visually-hidden">Loading...</span>
    </div>

    <div class="spinner-grow text-danger" role="status">
    <span class="visually-hidden">Loading...</span>
    </div>

    <div class="spinner-grow text-warning" role="status" >
    <span class="visually-hidden">Loading...</span>
    </div>

    </div>

 )
 }

export default Loop;
