import React from 'react';
import Grid from '@mui/material/Grid';
import { Helmet } from 'react-helmet';

const Blog = () => {
  
return(
  <>
  <Helmet>
  <title>Nulfye Blog | Latest Blogs on Content writing through AI</title>
  <meta name="description" content="Explore AI-powered content writing tips on our blog. Learn to create SEO-friendly, high-quality content efficiently using advanced AI tools and techniques." />
  </Helmet>
  <Grid item container >
  <Grid item xs={false} sm={false} md={2}/>

  <Grid item xs={12} sm={12} md={8}>
  </Grid>

  <Grid item xs={false} sm={false} md={2}/>
  </Grid>

  </>

  )
  }

export default Blog;












