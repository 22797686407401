import React from 'react';
import ModalVideo from './ModalVideo';
import Blocks from './Block';
import VideoThumb from './Nulfye.webp';
import HomeBlogs from './HomeBlogs';
import Grid from '@mui/material/Grid';
import './css.css';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';


const HomeA = (props)  => {
  

  return(
  <>
  <Helmet>
    <title>High quality, content creator - Nulfye
    </title>
    <meta name="description" content="Explore AI-driven content writing blogs. Learn to create SEO-friendly, engaging content with cutting-edge AI tools and tips to boost efficiency and creativity." />
    </Helmet>
  <section>
  <div className="max-w-6xl mx-auto px-2 sm:px-6">
  <div className="relative pt-8 pb-8 md:pt-12 md:pb-16 lg:pt-16">
  <div className="text-center pb-12 md:pb-8 lg:pb-16">
  <h1 className="text-3xl text-left md:text-4xl lg:text-5xl sm:text-center font-extrabold leading-tighter tracking-tighter mb-4 " data-aos="zoom-y-out"> Effortless Content Creation :  <span className="bg-clip-text text-transparent bg-gradient-to-r from-orange-500  to-yellow-500">Generate Quality with a Single Click!</span></h1>
  <p className="text-left md:text-center text-lg md:text-xl mt-1 md:mt-2 lg:text-2xl mb-2 text-gray-600" data-aos="zoom-y-out" data-aos-delay="150" >Discover the ultimate tool for streamlined content creation. With just a click, unleash professionally crafted articles, blogs, and more. Effortlessly save time while maintaining quality with our innovative solution. Whether you're a blogger, marketer, or business owner, revolutionize your content strategy today!</p>
  </div>
  
  <div class="macbook" className="hidden lg:block">
  <div class="display">
  <div class="screen">
  <ModalVideo 
  thumb={VideoThumb}
  thumbAlt="Modal video thumbnail"
  video='/convid/nulli.mp4'
  videoWidth={1920}
  videoHeight={1080}/>
  </div>
  </div>
  <div class="base">
  <div class="indent"></div>
  </div>
  <div class="bottom"></div>
  </div>
  
  
  <div className="hidden md:block lg:hidden ">
  <div class="ipad-pro-portrait" >
  <div class="bezeln"></div>
  <div class="casingn"></div>
  <div class="cameran"></div>
  <div class="home-btnn"></div>
  <div class="screenn">

  <ModalVideo
  as={Fragment}
  thumb={VideoThumb}
  thumbAlt="Modal video thumbnail"
  video='/convid/nulli.mp4'
  videoWidth={1920}
  videoHeight={1080}/>
  </div>
  </div>
  
  </div>
  <div className='flex flex-wrap  justify-center'>
  <div class="iphone" className=" md:hidden lg:hidden ">
  <div class="iphone-14">
  <div class="operator-name">Airtel</div>

  <div class="dynamic-island"></div>
  <div class="dynamic-island-camera">
  </div>
  <div class="operator-icons">
  <i class="bi bi-reception-4"></i>5G
  <div class="battery">100</div>
  </div>
  <div class="date-area">
  </div>
  <ModalVideo
  as={Fragment}

  thumb={VideoThumb}
  thumbAlt="Nulfye explains how it generate an article with just a single click"
  video='/convid/nulli.mp4'
  videoWidth={1920}
  videoHeight={1080}/>
  </div>
  </div> 
  </div>
  </div>
  </div> 
  </section>

  <section>
  
  <div className="max-w-6xl mx-auto px-2">
  <div className="py-12 md:py-20 border-t border-gray-100">
  <div className="max-w-4xl mx-auto text-center pb-12 md:pb-16">
  <h1 class="text-gray-800 text-left text-2xl md:text-3xl lg:text-4xl hover:underline md:text-center"><strong>AI-Powered Content Writing Tools: Cost-Effective Excellence Comparable to Human Creators</strong></h1>
  </div>

  <div className="grid gap-20">
  <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
  <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1 " data-aos="fade-up">
  <img className=" mx-auto h-[350px] w-[300px] md:h-[450px] md:w-[400px] lg:h-[550px] lg:w-[500px]" src='/conpic/SEO.webp'  alt="feature 02" />
  </div>

  <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-aos="fade-right">
  <div className="md:pr-4 lg:pr-12 xl:pr-16">
  <div className="font-architects-daughter text-xl text-purple-600 mb-2"></div>
  <h1 className="text-xl md:text-2xl lg:text-3xl mb-2 text-left md:mb-4 lg:mb-8 text-left" >SEO Optimized Content</h1>
  <p className="text-left text-lg md:text-xl mt-1 md:mt-2 text-gray-600" >Utilize AI-powered content creation tools to generate SEO-optimized content that ranks high in search engines. These tools streamline keyword research, integrate strategic keywords seamlessly, and ensure readability.<br/><br/> From blogs to product pages, they optimize meta tags, headings, and content structure to enhance visibility and engagement. By leveraging AI, businesses can efficiently drive organic traffic and improve search engine rankings, empowering digital strategies with effective content creation.  </p>
  </div></div></div>

  <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
  <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:mb-0 rtl" data-aos="fade-up">
  <img className=" mx-auto h-[300px] w-[300px] md:h-[450px] md:w-[400px] lg:h-[550px] lg:w-[500px]"  src='/conpic/update.webp'  alt="Features 03" />
  </div>
  <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-aos="fade-right">
  <div className="md:pr-4 lg:pr-12 xl:pr-16">
  <h1 className="text-xl md:text-2xl lg:text-3xl mb-2 text-left md:mb-4 lg:mb-8 text-left">Fully Automatic Content Writer</h1><br/>
  <p className="text-left text-lg md:text-xl mt-1 md:mt-2 text-gray-600">The Fully Automatic Content Writer tool harnesses AI to generate comprehensive and engaging content effortlessly. Using advanced natural language processing algorithms, it produces high-quality articles, blogs, and more with minimal human input.<br/><br/> From researching topics to crafting cohesive narratives, these tool ensures readability and relevance while optimizing for SEO. Ideal for busy professionals and businesses, it accelerates content creation processes, enhances productivity, and delivers consistently polished results tailored to meet diverse content needs.</p>
  </div></div></div></div></div></div> 

  </section>

  <div class="px-3 sm:px-10 md:px-20 lg:px-10 xl:px-20 py-12 bg-indigo-100" id="features">

  <Grid item container >
  <Grid item xs={false} sm={false} md={1} lg={1} xl={1.5}/>
  <Grid item xs={12} sm={12} md={10} lg={10} xl={9}>
  <div >

  <h1 class="text-gray-800 text-center text-2xl md:text-3xl lg:text-4xl p-2  hover:underline"><strong>How Content writing Tools Works</strong></h1>
  <div class="flex flex-col items-center flex-wrap lg:flex-row lg:items-stretch lg:flex-no-wrap lg:justify-between">
  <div class="w-full max-w-sm mt-6 lg:mt-8 bg-gray-100 rounded shadow-lg p-10 lg:p-8 lg:mx-4 xl:p-12">
  <div class="p-4 inline-block bg-indigo-200 rounded-lg">
            
  <svg className='h-[40px] w-[40px] md:h-[60px] md:w-[60px] lg:h-[80px] lg:w-[80px]' viewBox="0 0 384 512" fill="currentColor" {...props}>
  <path d="M112.1 454.3c0 6.297 1.816 12.44 5.284 17.69l17.14 25.69c5.25 7.875 17.17 14.28 26.64 14.28h61.67c9.438 0 21.36-6.401 26.61-14.28l17.08-25.68c2.938-4.438 5.348-12.37 5.348-17.7l.128-39.2H112l.1 39.2zM192 0C90.02.32 16 82.97 16 175.1c0 44.38 16.44 84.84 43.56 115.8 16.53 18.84 42.34 58.23 52.22 91.45.031.25.094.517.125.782h160.2c.031-.265.094-.516.125-.782 9.875-33.22 35.69-72.61 52.22-91.45C351.6 260.8 368 220.4 368 175.1 368 78.8 289.2.004 192 0zm96.4 260.1c-15.66 17.85-35.04 46.3-49.05 75.89h-94.61c-14.01-29.59-33.39-58.04-49.04-75.88C75.24 236.8 64 206.1 64 175.1 64 113.3 112.1 48.25 191.1 48 262.6 48 320 105.4 320 175.1c0 31-11.2 61.7-31.6 85zM176 80c-44.1 0-80 35.9-80 80 0 8.844 7.156 16 16 16s16-7.2 16-16c0-26.47 21.53-48 48-48 8.844 0 16-7.148 16-15.99S184.8 80 176 80z" />
  </svg>
  </div>
          
  <div class="mt-4 font-extrabold text-xl md:text-2xl tracking-wide mb-4 ">Instruct your Content Writter Tool</div>
  <div class="text-md md:text-lg text-left">Enter your keyword in the Textfield and any instructions into the Content writter tool system.</div>
  </div>
  <div class="w-full max-w-sm mt-8 bg-gray-100 rounded shadow-lg p-10 lg:p-8 lg:mx-4 xl:p-12">
  <div class="p-4 inline-block bg-green-200 rounded-lg">
  <svg  className='h-[40px] w-[40px] md:h-[60px] md:w-[60px] lg:h-[80px] lg:w-[80px]'  viewBox="0 0 1024 1024"  fill="currentColor"  {...props}>
  <path d="M864 736c0-111.6-65.4-208-160-252.9V317.3c0-15.1-5.3-29.7-15.1-41.2L536.5 95.4C530.1 87.8 521 84 512 84s-18.1 3.8-24.5 11.4L335.1 276.1a63.97 63.97 0 00-15.1 41.2v165.8C225.4 528 160 624.4 160 736h156.5c-2.3 7.2-3.5 15-3.5 23.8 0 22.1 7.6 43.7 21.4 60.8a97.2 97.2 0 0043.1 30.6c23.1 54 75.6 88.8 134.5 88.8 29.1 0 57.3-8.6 81.4-24.8 23.6-15.8 41.9-37.9 53-64a97 97 0 0043.1-30.5 97.52 97.52 0 0021.4-60.8c0-8.4-1.1-16.4-3.1-23.8H864zM762.3 621.4c9.4 14.6 17 30.3 22.5 46.6H700V558.7a211.6 211.6 0 0162.3 62.7zM388 483.1V318.8l124-147 124 147V668H388V483.1zM239.2 668c5.5-16.3 13.1-32 22.5-46.6 16.3-25.2 37.5-46.5 62.3-62.7V668h-84.8zm388.9 116.2c-5.2 3-11.2 4.2-17.1 3.4l-19.5-2.4-2.8 19.4c-5.4 37.9-38.4 66.5-76.7 66.5-38.3 0-71.3-28.6-76.7-66.5l-2.8-19.5-19.5 2.5a27.7 27.7 0 01-17.1-3.5c-8.7-5-14.1-14.3-14.1-24.4 0-10.6 5.9-19.4 14.6-23.8h231.3c8.8 4.5 14.6 13.3 14.6 23.8-.1 10.2-5.5 19.6-14.2 24.5zM464 400a48 48 0 1096 0 48 48 0 10-96 0z" />
  </svg>
  </div>
          
  <div class="mt-4 font-extrabold text-xl md:text-2xl tracking-wide mb-4">Wait some Seconds</div>
  <div class="text-md md:text-lg text-left">During those seconds, Content Writter will intelligently research, plan out, and write an entire high quality, completely unique Content automatically.</div>
  </div>
  <div class="w-full max-w-sm mt-8 bg-gray-100 rounded shadow-lg p-10 lg:p-8 lg:mx-4 xl:p-12">
  <div class="p-4 inline-block bg-red-200 rounded-lg">
  <svg className='h-[40px] w-[40px] md:h-[60px] md:w-[60px] lg:h-[80px] lg:w-[80px]' fill="none" viewBox="0 0 15 15"  {...props}>
  <path stroke="currentColor" d="M11 1.5h2.5v12a1 1 0 01-1 1h-10a1 1 0 01-1-1v-12H4m1 7l2 2 3.5-4m-6-6h6v2a1 1 0 01-1 1h-4a1 1 0 01-1-1v-2z" />
  </svg>
  </div>
  <div className="mt-4 font-extrabold text-xl md:text-2xl tracking-wide mb-4"> Receive your Content In no Time </div>
  <div class="text-md md:text-lg text-left">That's it! You have your Content and can do whatever you want with it.</div> 
  </div> </div> </div> 

  </Grid>
  < Grid item xs={false} sm={false} md={1} lg={1} xl={1.5} />
  </Grid>
  </div>
  <Blocks/>

  <div className='mb-16'>
  <HomeBlogs/>
  </div>
  </>
  
 )

 };

export default HomeA;
