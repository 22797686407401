import React , { useState } from 'react';
import Bla from './Bla';
import { bla } from './Cata';
import Grid from '@mui/material/Grid';
import { Typography } from "@material-tailwind/react";
import { Button } from "@material-tailwind/react";

const HomeBlogs = () => {
  const [blogs, setBlogs] = useState(bla);

  return (
    <>
    <Grid item container >
    <Grid item xs={false} sm={false} md={1} lg={2} xl={2}/>
    <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
    <section className='bg-[]'>
    <h4 className='text-gray-800 text-center text-2xl md:text-3xl lg:text-4xl p-2 mt-16 md:mt-20 lg:mt-24 hover:underline'><strong>Recent Blogs</strong></h4>

    <p className='text-center p-1 text-lg md:text-xl mt-1 md:mt-2 lg:text-2xl mb-4'>Some new & fresh blogs from our blog section</p>
    <div className='p-2 mb-2'>
    <Bla blogs={blogs}/></div> 
   
    <a href="/blogs/">
    <Button ripple={false}  className="bg-[#ffd01a] mt-4 mb-4 shadow-none hover:scale-105 hover:shadow-none focus:scale-105 focus:shadow-none active:scale-100 ">
    <Typography color="black" className="font-large font-sans	"> View All Articles </Typography>
    </Button>
    </a>
    </section>
    </Grid>
    < Grid item xs={false} sm={false} md={1} lg={2} xl={2}/>
    </Grid>
    </>
      
  );
  };

export default HomeBlogs;